import React from 'react'

//  Default Component to show when loading auth page.
const AuthenticatingPage = () => {
  return (
    <div>
      <h4> Authenticating you... one moment...</h4>
    </div>
  )
}

export default AuthenticatingPage
