import SocketManager from './socket-manager'

export const connect = ({
  eventBusURL,
  namespace,
  session,
  waitForConnection,
  onOpen,
  onConnectionChange,
  onReconnect,
  onMaximum,
  onClose,
  onError,
  options
}) => {
  const connection = new SocketManager({
    eventBusURL,
    namespace,
    session,
    waitForConnection,
    onOpen,
    onConnectionChange,
    onReconnect,
    onMaximum,
    onClose,
    onError,
    options
  })
  connection.connect()
  return connection
}
